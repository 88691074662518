import React from 'react';

import { Stack } from '@chakra-ui/core';

import Layout from '../components/display/layout';
import SEO from '../components/seo';
import Converter from '../components/games/converter_manager';

import { ConverterText } from '../components/textfiles/gametexts';

const ConverterPage = () => (
  <Layout>
    <SEO title={ConverterText.title} />
    <Stack spacing={8} align="center">
      <Converter />
    </Stack>
  </Layout>
);

export default ConverterPage;
