
import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Stack,
  Grid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Heading,
  Text,
  Textarea,
  theme,
} from '@chakra-ui/core';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import {
  validateBinary,
  validateDecimal,
  validateHexadecimal,
  validateBase64,
} from '../logic/formvalidator';
import converting from '../logic/numeric_converter';
import { ConverterText } from '../textfiles/gametexts';
import { FormConverterText } from '../textfiles/maintexts';


class Converter extends React.Component {
  constructor(props) {
    super(props);

    this.reset = this.reset.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      bin: '',
      dec: '',
      hex: '',
      b64: '',
      ascii: '',
      bytes: '',
    };
  }

  componentDidMount() {
    this.reset();
  }

  reset() {
    this.setState({
      bin: '',
      dec: '',
      hex: '',
      b64: '',
      ascii: '',
      bytes: '',
    });
  }

  convert(type, values) {
    const converted = {};
    switch (type) {
      case 'bin':
        converted.bin = values.bin;
        converted.hex = converting.binToHex(values.bin);
        converted.dec = converting.binToDec(values.bin);
        converted.bytes = converting.binToDec(values.bin, true);
        converted.ascii = converting.binToAscii(values.bin);
        converted.b64 = converting.binToB64(values.bin);
        break;
      case 'hex':
        converted.hex = values.hex;
        converted.bin = converting.hexToBin(values.hex);
        converted.dec = converting.hexToDec(values.hex);
        converted.bytes = converting.hexToDec(values.hex, true);
        converted.ascii = converting.hexToAscii(values.hex);
        converted.b64 = converting.hexToB64(values.hex);
        break;
      case 'dec':
        converted.dec = values.dec;
        converted.bin = converting.decToBin(values.dec);
        converted.ascii = converting.decToAscii(values.dec);
        converted.hex = converting.decToHex(values.dec);
        converted.b64 = converting.decToB64(values.dec);
        converted.bytes = converting.decToBytes(values.dec);
        break;
      case 'bytes':
        converted.bytes = values.bytes;
        converted.bin = converting.bytesToBin(values.bytes);
        converted.ascii = converting.bytesToAscii(values.bytes);
        converted.hex = converting.bytesToHex(values.bytes);
        converted.b64 = converting.bytesToB64(values.bytes);
        converted.dec = converting.bytesToDec(values.bytes);
        break;
      case 'ascii':
        converted.ascii = values.ascii;
        converted.dec = converting.asciiToDec(values.ascii);
        converted.bytes = converting.asciiToDec(values.ascii, true);
        converted.bin = converting.asciiToBin(values.ascii);
        converted.b64 = converting.asciiToB64(values.ascii);
        converted.hex = converting.asciiToHex(values.ascii);
        break;
      case 'b64':
        converted.b64 = values.b64;
        converted.dec = converting.b64ToDec(values.b64);
        converted.ascii = converting.b64ToAscii(values.b64);
        converted.bin = converting.b64ToBin(values.b64);
        converted.hex = converting.b64ToHex(values.b64);
        converted.bytes = converting.b64ToHex(values.b64, true);
        break;
      default:
        break;
    }

    if (converted.bin) this.setState({ ...converted });
  }

  submitForm(type, values, setSubmitting) {
    try {
      this.convert(type, values);
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  }

  render() {
    const validateType = {
      bin: (...args) => validateBinary(...args, true),
      hex: (...args) => validateHexadecimal(...args, true),
      dec: validateDecimal,
      bytes: (...args) => validateDecimal(...args, true),
      b64: validateBase64,
      ascii: () => null,
    };

    return (
      <Box
        borderWidth="5px"
        rounded="md"
        align="center"
        m={2}
        p={3}
        style={{
          maxWidth: '900px',
          minWidth: '350',
          width: '95vw',
          textAlign: '-webkit-center',
        }}
      >
        {['bin', 'dec', 'bytes', 'hex', 'ascii'].map((type) => (
          <Formik
            key={type}
            initialValues={{ [type]: this.state[type] }}
            onSubmit={(values, { setSubmitting }) => this.submitForm(type, values, setSubmitting)}
            enableReinitialize
          >
            {(props) => (
              <Form style={{ width: '90vw', maxWidth: '650px' }}>
                <Grid gap={3}>
                  <Box>
                    <Field
                      key={type}
                      name={type}
                      validate={(val) => validateType[type](val, false)}
                    >
                      {({ field, form }) => (
                        <FormControl isInvalid={form.touched[type] && form.errors[type]}>
                          <FormLabel htmlFor={type}>
                            {FormConverterText[type].label}
                          </FormLabel>
                          <Input
                            id={type}
                            placeholder={FormConverterText[type].helper}
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors[type]}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      // variantColor={buttonColor}
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      {ConverterText.button.convert}
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        ))}
      </Box>
    );
  }
}

export default Converter;
