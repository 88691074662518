const stripSpaces = (str) => str.split(' ').join('');

// From Decimal
const decToBin = (value) => {
  return parseInt(value, 10).toString(2);
};
const decToHex = (value) => {
  const res = parseInt(value, 10).toString(16).toUpperCase();
  return res.padStart(res.length % 2 ? res.length + 1 : 0, 0);
};
const decToAscii = (value) => {
  const res = decToHex(value).match(/.{1,2}/g); // split by pair of character
  return res.reduce((acc, nb) => (
    acc + String.fromCharCode(parseInt(nb, 16))
  ), '');
};
const decToB64 = (value) => {
  return parseInt(value, 10);
};
const decToBytes = (value) => {
  const res = decToHex(value).match(/.{1,2}/g); // split by pair of character
  return res.reduce((acc, nb) => (
    acc + ' ' + parseInt(nb, 16)
  ), '');
};

// From Binary
const binToHex = (value) => {
  const res = parseInt(stripSpaces(value), 2).toString(16).toUpperCase();
  return res.padStart((res.length % 2) ? res.length + 1 : 0, 0);
};
const binToDec = (value, asBytes) => {
  const ret = parseInt(stripSpaces(value), 2).toString();
  if (asBytes) return decToBytes(ret);
  return ret;
};
const binToAscii = (value) => {
  const res = binToHex(stripSpaces(value)).match(/.{1,2}/g); // split by pair of character
  return res.reduce((acc, nb) => (
    acc + String.fromCharCode(parseInt(nb, 16))
  ), '');
};
const binToB64 = (value) => {
  return parseInt(stripSpaces(value), 10);
};

// From Hexadecimal
const hexToBin = (value) => {
  return parseInt(stripSpaces(value), 16).toString(2);
};
const hexToDec = (value, asBytes) => {
  const ret = parseInt(stripSpaces(value), 16).toString(10);
  if (asBytes) return decToBytes(ret);
  return ret;
};
const hexToAscii = (value) => {
  const res = stripSpaces(value)
    .padStart((value.length % 2) ? value.length + 1 : 0, 0)
    .match(/.{1,2}/g); // split by pair of character
  return res.reduce((acc, nb) => (
    acc + String.fromCharCode(parseInt(nb, 16))
  ), '');
};
const hexToB64 = (value) => {
  return parseInt(stripSpaces(value), 10);
};

// From Ascii
const asciiToBin = (value) => {
  const res = value.split('');
  return res.reduce((acc, char) => (
    acc + decToBin(char.charCodeAt(0))
  ), '');
};
const asciiToHex = (value) => {
  const res = value.split('');
  return res.reduce((acc, char) => (
    acc + char.charCodeAt(0)
  ), '');
};
const asciiToDec = (value, asBytes) => {
  const res = value.split('');
  const ret = res.reduce((acc, char) => (
    acc + char.charCodeAt(0)
  ), '');
  if (asBytes) return decToBytes(ret);
  return ret;
};
const asciiToB64 = (value) => {
  return parseInt(value, 10);
};

// From decimal Bytes
const bytesToHex = (value) => {
  const res = value.split(' ');
  return res.reduce((acc, nb) => (nb ? acc + decToHex(nb) : acc), '');
};
const bytesToBin = (value) => {
  return hexToBin(bytesToHex(value));
};
const bytesToDec = (value) => {
  return hexToDec(bytesToHex(value));
};
const bytesToAscii = (value) => {
  return hexToAscii(bytesToHex(value));
};
const bytesToB64 = (value) => {
  return value;
};

// From Base 64
const b64ToBin = (value) => {
  return parseInt(value, 10);
};
const b64ToDec = (value) => {
  return parseInt(value, 10);
};
const b64ToHex = (value) => {
  return parseInt(value, 10);
};
const b64ToAscii = (value) => {
  return parseInt(value, 10);
};

const converters = {
  decToBin,
  decToHex,
  decToAscii,
  decToB64,
  decToBytes,
  binToHex,
  binToDec,
  binToAscii,
  binToB64,
  hexToBin,
  hexToDec,
  hexToAscii,
  hexToB64,
  asciiToBin,
  asciiToDec,
  asciiToHex,
  asciiToB64,
  bytesToHex,
  bytesToBin,
  bytesToDec,
  bytesToAscii,
  bytesToB64,
  b64ToBin,
  b64ToDec,
  b64ToHex,
  b64ToAscii,
};

export default converters;
