export const CssGameText = {
  title: 'Mini-jeu',
  button: {
    menu: 'Retour au menu',
    validate: 'Valider',
    start: 'GO!',
    restart: 'Recommencer',
    next: 'Continuer',
  },
  gameover: 'Game Over...',
  ready: 'Prêt ?',
  win: 'Bien joué tu as fini le jeu!!!',
  level: {
    lvl1: 'Niveau 1',
    lvl2: 'Niveau 2',
  },
};

export const NainsText = {
  title: 'Nain bleu',
};

export const ConverterText = {
  title: 'Converter',
  button: {
    convert: 'convert',
  },
};
